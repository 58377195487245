// Generated by Framer (bdd6aa1)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, Link, RichText, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";
import * as sharedStyle from "../css/xZndidUCt";

const enabledGestures = {mqPakEqb3: {hover: true}};

const cycleOrder = ["mqPakEqb3", "CpaqdrdEG"];

const variantClassNames = {CpaqdrdEG: "framer-v-cxlte", mqPakEqb3: "framer-v-qn1npc"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transitions = {default: {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}};

const transformTemplate = (_, t) => `translate(-50%, -50%) ${t}`

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const humanReadableVariantMap = {"Variant 1": "mqPakEqb3", "Variant 2": "CpaqdrdEG"}

const getProps = ({height, id, title, width, ...props}) => { return {...props, variant: humanReadableVariantMap[props.variant] ?? props.variant ?? "mqPakEqb3", xyKBA561i: title ?? props.xyKBA561i ?? "Termékek"} }

const createLayoutDependency = (props, variants) => variants.join('-') + props.layoutDependency

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;variant?: keyof typeof humanReadableVariantMap;title?: string; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale } = useLocaleInfo()

const {style, className, layoutId, variant, xyKBA561i, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "mqPakEqb3", enabledGestures, transitions, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const isDisplayed = () => {
if (gestureVariant === "mqPakEqb3-hover") return false
return true
}

const defaultLayoutId = React.useId()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<motion.div initial={variant} animate={variants} onHoverStart={() => setGestureState({isHovered: true})} onHoverEnd={() => setGestureState({isHovered: false})} onTapStart={() => setGestureState({isPressed: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} className={cx("framer-7hiNM", sharedStyle.className, classNames)} style={{display: "contents"}}>
<Transition value={transition}><Link href={{hash: ":Xa9O2Rhg8", webPageId: "pH3J1KNIf"}} openInNewTab={false} smoothScroll><motion.a {...restProps} className={`${cx("framer-qn1npc", className)} framer-1hlymtj`} data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"mqPakEqb3"} ref={ref} style={{...style}} {...addPropertyOverrides({"mqPakEqb3-hover": {"data-framer-name": undefined}, CpaqdrdEG: {"data-framer-name": "Variant 2"}}, baseVariant, gestureVariant)}>{isDisplayed() && (<RichText __fromCanvasComponent children={<React.Fragment><motion.p className={"framer-styles-preset-21ogod"} data-styles-preset={"xZndidUCt"} style={{"--framer-text-alignment": "center"}}>Kapcsolat</motion.p></React.Fragment>} className={"framer-10vgey4"} layoutDependency={layoutDependency} layoutId={"TmRcRfX0Z"} style={{"--framer-paragraph-spacing": "0px"}} text={xyKBA561i} transformTemplate={transformTemplate} verticalAlignment={"top"} withExternalLayout/>)}</motion.a></Link></Transition>
</motion.div>
</LayoutGroup>)

});

const css = [".framer-7hiNM [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-7hiNM .framer-1hlymtj { display: block; }", ".framer-7hiNM .framer-qn1npc { height: 32px; overflow: visible; position: relative; text-decoration: none; width: 75px; }", ".framer-7hiNM .framer-10vgey4 { flex: none; height: auto; left: 49%; overflow: visible; position: absolute; top: 50%; white-space: pre; width: auto; }", ".framer-7hiNM .framer-v-qn1npc .framer-qn1npc { cursor: pointer; }", ".framer-7hiNM.framer-v-qn1npc.hover .framer-qn1npc { height: 114px; overflow: hidden; }", ...sharedStyle.css]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 32
 * @framerIntrinsicWidth 75
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]},"CpaqdrdEG":{"layout":["fixed","fixed"]},"Gzv0cShGe":{"layout":["fixed","fixed"]}}}
 * @framerVariables {"xyKBA561i":"title"}
 */
const FramerLdFV2XSaJ: React.ComponentType<Props> = withCSS(Component, css, "framer-7hiNM") as typeof Component;
export default FramerLdFV2XSaJ;

FramerLdFV2XSaJ.displayName = "Kapcsolat";

FramerLdFV2XSaJ.defaultProps = {height: 32, width: 75};

addPropertyControls(FramerLdFV2XSaJ, {variant: {options: ["mqPakEqb3", "CpaqdrdEG"], optionTitles: ["Variant 1", "Variant 2"], title: "Variant", type: ControlType.Enum}, xyKBA561i: {defaultValue: "Termékek", displayTextArea: false, title: "Title", type: ControlType.String}} as any)

addFonts(FramerLdFV2XSaJ, [...sharedStyle.fonts])